import { createAction, props } from '@ngrx/store';
import { SchedulePensionsJobRequest } from '@app/management/models/requests/schedule-pension-job-request';
import { getActionName } from '@app/core/state/state-helpers';
import { ScheduleRebateCalculationJobRequest } from '@app/management/models/requests/schedule-rebate-calculation-job-request';

const actionPrefix = '[Management]';
export const schedulePensionJob = createAction(
  getActionName('Schedule Pension Job', actionPrefix),
  props<{ request: SchedulePensionsJobRequest }>(),
);

export const schedulePensionJobSuccess = createAction(
  getActionName('Schedule Pension Job Success', actionPrefix),
);

export const schedulePensionJobFailure = createAction(
  getActionName('Schedule Pension Job Failure', actionPrefix),
  props<{ error: string }>(),
);

export const scheduleRebateCalculation = createAction(
  getActionName('Schedule Rebate Job', actionPrefix),
  props<{ request: ScheduleRebateCalculationJobRequest }>(),
);

export const scheduleRebateCalculationSuccess = createAction(
  getActionName('Schedule Rebate Job Success', actionPrefix),
);

export const scheduleRebateCalculationFailure = createAction(
  getActionName('Schedule Rebate Job Failure', actionPrefix),
  props<{ error: string }>(),
);

export const scheduleReinsuranceEventFieldSync = createAction(
  getActionName('Schedule Reinsurance Event Field Sync Job', actionPrefix),
);

export const scheduleReinsuranceEventFieldSyncSuccess = createAction(
  getActionName(
    'Schedule Reinsurance Event Field Sync Job Success',
    actionPrefix,
  ),
);

export const scheduleReinsuranceEventFieldSyncFailure = createAction(
  getActionName(
    'Schedule Reinsurance Event Field Sync Job Failure',
    actionPrefix,
  ),
  props<{ error: string }>(),
);
